import React, { useState, useEffect } from "react";

import BadgeImage from "../../Components/Badges";

import calendarVector from "../../../Asset/Events/calendar-vector.svg";
import locationVector from "../../../Asset/Events/location-vector.svg";
import timeVector from "../../../Asset/Events/time-vector.svg";
import coinVector from "../../../Asset/Events/coin-vector.svg";
import ShowSponsors from "../EventComponents/ShowSponsors";
import "./MoreEventCard.css"
import { format } from "date-fns";
import { Link } from 'react-router-dom';
import Axios from "axios";

const MoreEventCard = ({ eventData, showTitle = true, sliceTill }) => {
  const BadgeSize = { width: "19rem", height: "19rem" };
  const [eventImages, setEventImages] = useState([]);
  const [eventImagesMobile, setEventImagesMobile] = useState([]);



  useEffect(() => {
    const fetchImages = async () => {
      try {
        // Use Promise.all to fetch images for all events in parallel
        const imagePromises = eventData.map(async (event) => {
          try {
            

            if (!event.EventImages || event.EventImages.length === 0) {
              console.error("No images available for event", event.EventId);
              return {
                eventId: event.EventId,
                tallImageUrl: "",
                wideImageUrl: "",
              };
            }

            // Find tall and wide images
            const tallImage = event.EventImages.find((image) =>
              image.Tags.includes("TALL")
            );
            const wideImage = event.EventImages.find((image) =>
              image.Tags.includes("WIDE")
            );

            const tallImageFileId = tallImage?.FileId || null;
            const wideImageFileId = wideImage?.FileId || null;

            // If either image is missing, log an error and return default
            if (!tallImageFileId) {
              console.error("No tall image found for event", event.EventId);
            }
            if (!wideImageFileId) {
              console.error("No wide image found for event", event.EventId);
            }

            // Fetch the images if IDs exist
            const tallImageRes =
              tallImageFileId &&
              (await Axios.get(
                `${process.env.REACT_APP_BASE_URL}/files/${tallImageFileId}/download`,
                { responseType: "blob" }
              ));
            const wideImageRes =
              wideImageFileId &&
              (await Axios.get(
                `${process.env.REACT_APP_BASE_URL}/files/${wideImageFileId}/download`,
                { responseType: "blob" }
              ));

            const tallImageUrl = tallImageRes
              ? URL.createObjectURL(tallImageRes.data)
              : "";
            const wideImageUrl = wideImageRes
              ? URL.createObjectURL(wideImageRes.data)
              : "";
              
              
            return {
              eventId: event.EventId,
              tallImageUrl,
              wideImageUrl
              
              
            };
          } catch (error) {
            console.error("Error fetching images for event", event.EventId, error);
            return {
              eventId: event.EventId,
              tallImageUrl: "",
              wideImageUrl: "",
            };
          }
        });

        // Wait for all image fetches to complete
        const images = await Promise.all(imagePromises);

        // Separate tall and wide images for their respective states
        const tallImages = images.map(({ eventId, tallImageUrl }) => ({
          eventId,
          tallImageUrl: tallImageUrl,
        }));

        
        

        const wideImages = images.map(({ eventId, wideImageUrl }) => ({
          eventId,
          wideImageUrl: wideImageUrl,
        }));
        

        // Update the states
        setEventImages(tallImages); // For tall images
        setEventImagesMobile(wideImages); // For wide images
      } catch (globalError) {
        console.error("Error in fetchImages function", globalError);
      }
    };

    if (eventData && eventData.length > 0) {
      fetchImages();
    }
  }, [eventData, setEventImages, setEventImagesMobile]);


  // Ensure eventData exists and has MoreEvents array
  if (!eventData || eventData.length === 0) {
    return null; // Return nothing if no data is present
  }

  return (
    <>
      <div className="moreEvents-container flex flex-col justify-center items-center  ">
        {showTitle && <h1 className="text-[var(--dark-blue)] text-3xl md:text-[2rem] font-medium w-[100%] md:pl-12 md:mb-12 font-ClashGrotesk pl-5 mb-5">More Events</h1>}
        {eventData.slice(0, sliceTill).map((moreevents) => {
          // Find the image URL for the current event
          const tallEventImage = eventImages.find((img) => img.eventId === moreevents.EventId)?.tallImageUrl || "";
          
          
          const wideEventImage = eventImagesMobile.find((img) => img.eventId === moreevents.EventId)?.wideImageUrl || "";
          
          

          return (
            <div className={`w-full ${!showTitle ? 'flex justify-center' : ''} `} key={moreevents.EventId}>


              {/* Card 1 Container */}
              <div className={`card1 mb-12 flex flex-col  justify-center items-start w-[93%] md:w-[92%] rounded-3xl  mx-auto md:mx-0 relative ${!showTitle ? '' : ' md:ml-12'} `}>

                <div id="moreEvent-card-sponsors" className={` w-full flex flex-wrap justify-start items-center ${moreevents?.sponsors?.length > 0 ? 'h-22 md:h-24' : 'h-4 md:h-24'}`}>
                  {moreevents?.sponsors?.length > 0 && (
                    <div id="moreEvents-card-sponsors" className="flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start pt-6 md:pt-3  md:pl-14 pl-6 md:gap-10 md:w-[64%]">
                      <div className="text-[var(--purple-blue)] text-base font-manrope font-semibold">Sponsors</div>
                      <span className="hidden md:block text-4xl text-[var(--purple-blue)] font-ClashGrotesk font-extralight">|</span>
                      <div className=" w-full -mt-2 md:mt-0">
                        <ShowSponsors eventData={moreevents} />
                      </div>

                    </div>
                  )}
                </div>

                {/* containing content of card */}
                <div id="moreEvents-card-content" className=" flex flex-col md:flex-row justify-center md:justify-between items-center w-[100%] md:w-[100%] rounded-3xl  md:mx-0 ">

                  {/* Inside container left part */}
                  <div className="flex flex-col md:flex-row md:w-[64%] items-center md:justify-start md:items-start md:pr-10 md:pl-14 md:pb-10 px-3 pb-3 md:gap-4 w-full ">
                    {/* Image */}
                    <div id="moreEvents-image-container" className="md:hidden w-full h-[14rem] p-3 ">
                      {wideEventImage ? (
                        <img src={wideEventImage} alt={moreevents.EventTitle} className="w-full  h-full rounded-2xl" />
                      ) : ""}
                    </div>
                    <div className="hidden md:block md:w-[35%]">
                      {tallEventImage ? (
                        <img className="w-full mt-1 rounded-[1.3rem]" src={tallEventImage} alt={moreevents.EventTitle} />
                      ) : ""}
                    </div>

                    {/* Text part */}
                    <div id="moreEvents-text-part" className="flex flex-col md:gap-5 md:mb-auto justify-between md:justify-start w-[94%] md:w-[80%] text-[var(--medium-purple)] md:pl-6 overflow-hidden">
                      <div className="flex">
                        {/* Heading */}
                        <div className="w-3/5 md:w-full">
                          <h1 className="xl:text-[3.25rem] md:leading-[3.25rem] md:text-3xl text-2xl font-manrope font-semibold py-2 pt-3 md:pt-0">
                            {moreevents.EventTitle}
                          </h1>
                        </div>

                        {/* Badge image for Mobile */}
                        <div className={`md:hidden w-[35%] ml-0 absolute left-[15rem]  ${moreevents?.sponsors?.length > 0 ? 'top-[19rem]' : 'top-[11.5rem]'}`}>
                          <BadgeImage EventType={moreevents.EventType} size={BadgeSize} />
                        </div>
                      </div>
                      <div className="pb-5 pt-7 md:pt-0 md:pb-0 overflow-hidden">
                        <p id="scroll-container" className=" font-ClashGrotesk font-medium md:w-[88%]   max-h-44 md:max-h-32 overflow-y-auto">{moreevents.EventDescription}</p>
                      </div>

                      <hr className="border border-[var(--medium-purple)] border-solid mb-4 md:w-[85%]" />

                      {/* Inside partition */}
                      <div className="flex pb-2 text-base w-full md:-mt-5 md:text-base font-manrope font-semibold text-[var(--gray-black)]">
                        <span className="flex gap-1 w-full md:w-[36%]">
                          <span className="flex-shrink-0" > <img src={calendarVector} alt="calendar vector" /></span>  {format(new Date(moreevents.event_schedule.StartDate), "do MMM, yyyy")}
                        </span>
                        <span className="flex flex-grow gap-1 w-full md:w-[36%]">
                          <span className="flex-shrink-0"> <img src={timeVector} alt="clock vector" /></span>
                          <span className="flex-wrap ">
                            <div className="inline-block">{format(new Date(moreevents.event_schedule.StartTimeStamp), "hh:mm a")}</div>
                            <div className="inline-block">-</div>
                            <div className="inline-block">{format(new Date(moreevents.event_schedule.EndTimeStamp), "hh:mm a")}</div>

                          </span>
                        </span>
                      </div>
                      <div className="flex pb-2 md:-mt-4 md:text-base font-manrope font-semibold text-[var(--gray-black)]">
                        <span className="flex gap-1 w-full md:w-[36%]">
                          <span className="flex-shrink-0"><img src={locationVector} alt="location vector" /></span>  {moreevents?.venue.VenueAddress.city}
                        </span>
                        <span className="flex flex-grow  gap-1 w-full md:w-[36%]">
                          <span className="flex-shrink-0"> <img src={coinVector} alt="coin vector" /></span> ₹{moreevents.Price}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Inside container right part */}
                  <div className="flex flex-col justify-between md:items-end items-center md:w-[37%] md:gap-16 gap-2 p-4 md:mr-5">
                    <div className="md:-mt-[10rem] md:-mr-10 hidden md:block">
                      <BadgeImage EventType={moreevents.EventType} size={BadgeSize} />
                    </div>

                    {/* Register Button */}
                    <Link to={`/events/${moreevents.EventId}`} >
                      <button className=" border-solid border-2 border-[var(--medium-purple)] text-[var(--purple-blue)] text-lg md:px-32 px-32 py-[0.75rem]  rounded-xl md:mr-4 font-medium font-manrope text-[1.1rem] cursor-pointer mt-12 mb-8 md:mt-0 md:mb-0">
                        Register
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MoreEventCard;
