import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const OrderPaymentCompletion = () => {
  const { suborderId } = useParams();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const handleCheckout = async () => {
      setLoader(true); // Start loader
      try {
        // Perform the checkout API call
        const checkoutResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/checkout/?sub_order_id=${suborderId}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        );

        // Extract RazorpayOrderId and amount for Razorpay payment
        const { RazorpayOrderId, amount } = checkoutResponse.data;

        // Call Razorpay payment handling function
        handleRazorpayPayment(RazorpayOrderId, amount);
      } catch (error) {
        console.error("Error during checkout process:", error);
      } finally {
        setLoader(false); // Stop loader
      }
    };

    const handleRazorpayPayment = (RazorpayOrderId, amount) => {
      // Convert amount to paise (multiply INR by 100)
      const adjustedAmount = amount * 100;

      // Dynamically create a form to submit to Razorpay Hosted Checkout
      const form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute(
        "action",
        "https://api.razorpay.com/v1/checkout/embedded"
      );
      form.setAttribute("target", "_self"); // Open in the same tab

      // Define form fields dynamically
      const fields = [
        { name: "key_id", value: "rzp_test_w402YRQwCZ0zlG" }, // Razorpay key ID
        { name: "order_id", value: RazorpayOrderId }, // Order ID from backend
        { name: "amount", value: adjustedAmount }, // Amount in paise
        { name: "currency", value: "INR" },
        { name: "name", value: "Net Gala" },
        { name: "description", value: "Please complete your payment to proceed." },
        { name: "image", value: `${process.env.REACT_APP_HOSTED_URL}/logo.png` },
        { name: "callback_url", value: `${process.env.REACT_APP_BASE_URL}/payment/callback/` },
        { name: "cancel_url", value: `${process.env.REACT_APP_BASE_URL}/payment/cancel` },
      ];

      // Append fields to the form
      fields.forEach(({ name, value }) => {
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", name);
        input.setAttribute("value", value);
        form.appendChild(input);
      });

      // Append form to the body and submit it automatically
      document.body.appendChild(form);
      form.submit(); // Trigger the redirection to Razorpay
    };

    // Trigger checkout process on component mount
    handleCheckout();
  }, [suborderId]);

  return (
    <div className="w-[100vw] h-[100vh] flex justify-center items-center">
      {loader ? <ClipLoader color="Purple" size={20} /> : ""}
    </div>
  );
};

export default OrderPaymentCompletion;
